import h from '../../lib/react-hyperscript';
import * as _ from 'ramda';
import { Box, Stack, Typography } from '@mui/material';
import { fmt } from '../helper';
import * as amp from '../amp';
import { SellingHistory, BasicCalibration, AggregationLevel } from '../types';

import {
  toGrid,
  sellingHistorySvg,
  packageCalibrationHistorySvg,
} from '../gfx/charts';

const INDENT = '24px';

const stripScopeIds = (scope: string[]) => {
  return scope.map((s) => s.split(':')[0]).join('-');
};

const Swatch = ({
  color,
  opacity,
  stroke_width,
}: {
  color: string;
  opacity: number;
  stroke_width: number;
}) => {
  return h(Box, {
    sx: {
      display: 'inline-block',
      width: '10px',
      boxSizing: 'border-box',
      height: stroke_width * 1.5 + 'px',
      backgroundColor: color,
      opacity,
      margin: '4px 10px 4px 4px',
    },
  });
};

const CalibrationSwatch = ({
  calibration,
}: {
  calibration: BasicCalibration;
}) => {
  return h('div', {
    style: {
      width: '10px',
      height: '10px',
      marginRight: '7px',
      backgroundColor: amp.calibrationColor(amp.calibrationClass(calibration)),
      opacity: calibration.amount > 0 ? 0.6 : 0.6,
    },
  });
};

const Calibrations = ({
  calibrations,
}: {
  calibrations: BasicCalibration[];
}) => {
  // rturn calibrations separated beneath basic and pro headers
  return h(Stack, { sx: { width: '100%' } }, [
    h('table', { style: { width: 'fit-content', fontSize: '14px' } }, [
      h(
        'tbody',
        _.sortBy(amp.calibrationClass, calibrations).map((cal) =>
          h('tr', [
            h('td', [h(CalibrationSwatch, { calibration: cal })]),
            h('td', stripScopeIds(cal.scope)),
            h('td', { style: { textAlign: 'right', width: '80px' } }, [
              fmt.percentWithTwoDecimals(cal.amount),
            ]),
          ]),
        ),
      ),
    ]),
  ]);
};

const SellingHistoryBreakdown = ({
  data,
  level,
  selectedDate,
}: {
  data: SellingHistory;
  level: AggregationLevel;
  selectedDate: Date;
}) => {
  const calibrations = amp.activeCalibrations(
    selectedDate,
    amp.filterBasicPackageCalibrations(data.calibrations),
  );

  const total_cal = amp.total_basic_amount(calibrations);

  return h(Box, [
    h(
      Stack,
      {
        sx: {
          width: '100%',
        },
      },
      [
        h(Stack, [
          h(
            Stack,
            { marginLeft: INDENT, direction: 'row', alignItems: 'center' },
            [
              h(
                Typography,
                {
                  variant: 'body1',
                },
                'Calibrations ',
              ),
            ],
          ),
          h(
            Stack,
            {
              marginLeft: INDENT,
              marginTop: '-4px',
              direction: 'row',
              spacing: 2,
              width: '250px',
            },
            [
              h(
                Typography,
                {
                  variant: 'body2',
                  sx: { fontStyle: 'italic' },
                  ml: 1,
                },
                '' +
                  (level === 'ticket-package' ? 'package' : level) +
                  ' and above',
              ),
            ],
          ),
        ]),
        h(
          Stack,
          {
            marginTop: '4px',
            direction: 'row',
            width: '250px',
            alignItems: 'center',
          },
          [
            h(Swatch, amp.amp_adjustment_style),
            h(
              Typography,
              {
                variant: 'body2',
                title: '(1 + adj1) * (1 + adj2) * ... * (1 + adjN) - 1',
                width: '80px',
              },
              'total',
            ),
            h(
              Typography,
              { variant: 'body2', fontWeight: 'bold' },
              fmt.percentWithTwoDecimals(+total_cal)?.padStart(6, '0'),
            ),
          ],
        ),

        h(
          Stack,
          {
            marginLeft: INDENT,
            direction: 'row',
            width: '250px',
            marginBottom: '10px',
          },
          [
            h(
              Typography,
              {
                variant: 'body2',
                title: '(1 + adj1) * (1 + adj2) * ... * (1 + adjN) - 1',
                width: '80px',
              },
              'changed',
            ),
            h(
              Typography,
              { variant: 'body2', fontWeight: 'bold' },
              `${fmt.datetime(selectedDate.toString())}`,
            ),
          ],
        ),

        h(Calibrations, { calibrations }),
      ],
    ),
  ]);
};

export default SellingHistoryBreakdown;
