import { useBaseData, BaseDataProvider } from '../../../common/base_data';
import { format, PageDataGrid } from '../../../common/components';
import * as _ from 'ramda';
import { GridColDef } from '@mui/x-data-grid-pro';

const column_specs: GridColDef[] = [
  {
    field: 'day',
    align: 'right',
  },
  { field: 'inventory_id', headerName: 'inv_id', width: 100 },
  { field: 'seats', width: 100 },
  { field: 'days_to', width: 60 },
  { field: 'status', align: 'right' },
  {
    field: 'list_price',
    headerName: '$list',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'list_change',
    headerName: 'list±',
    align: 'right',
    valueFormatter: format.percent,
    type: 'number',
    width: 60,
  },
  {
    field: 'model_price',
    headerName: '$model',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'model_diff',
    headerName: 'modelΔ',
    align: 'right',
    valueFormatter: format.percent,
    type: 'number',
  },
];

const column_groups = [
  {
    groupId: 'Inventory',
    children: [{ field: 'day' }, { field: 'inventory_id' }, { field: 'seats' }],
  },
  {
    groupId: 'Status',
    children: [
      { field: 'days_to' },
      { field: 'status' },
      { field: 'list_price' },
      { field: 'list_change' },
      { field: 'model_price' },
      { field: 'model_diff' },
    ],
  },
];

const hydrateRows = (
  baseData: BaseDataProvider,
  rows: { client_id: number; home_team_id: number; away_team_id: number }[],
) => {
  return rows.map((row) => {
    const hteam = baseData.getTeam(row.home_team_id);
    const ateam = baseData.getTeam(row.away_team_id);
    return {
      ...row,
      team: hteam?.name_short,
      opponent: ateam?.name_short,
    };
  });
};

export default function InventoryHistoryDataGrid({ rows }: { rows: any[] }) {
  const { baseData } = useBaseData();
  rows = hydrateRows(baseData, rows);
  return PageDataGrid({
    id: 'inventory_price_history',
    getRowId: (row) => row.day + '-' + row.inventory_id,
    rows,
    column_specs,
    column_groups,
    hide_client_filter: true,
  });
}
