import * as d3 from 'd3';
import * as _ from 'remeda';
import { SellingHistory } from '../../types';
import { durationDays } from '../../helper';
import { UnsafeSharedValue } from '../../util/shared_value';
import { addScrubber } from '.';

type SeatState = 'unbroadcasted' | 'broadcasted' | 'sold';
type CountsTriplet = [number, number, number];
type SalePoint = {
  event_num: number;
  day_num: number;
  seat_addresses: string[];
};
type CountsGrid = (CountsTriplet | null)[][];
type Projection = {
  grid: CountsGrid;
  sales: SalePoint[];
  events: SellingHistory['events'];
  days: string[];
  start_date: Date;
  end_date: Date;
};

const formatDate = (date: Date): string => date.toISOString().slice(0, 10);

const end_date = new Date();

const toGrid = (selling_history: SellingHistory): Projection => {
  const date_range = _.range(
    0,
    Math.ceil((+end_date - +selling_history.shown) / (1000 * 60 * 60 * 24)),
  ).map((i) => {
    const d = new Date(selling_history.shown);
    d.setDate(d.getDate() + i);
    return formatDate(d);
  });
  const today = formatDate(new Date());

  const filtered = _.filter(selling_history.actions, (d) =>
    ['delist', 'sell', 'broadcast'].includes(d.type),
  );

  const actions_by_event = _.groupBy(filtered, (d) => d.event_id);

  const initial_seat_states = Object.fromEntries(
    selling_history.seat_addresses.map((address) => [
      address,
      'unbroadcasted' as SeatState,
    ]),
  );

  const grid: CountsGrid = date_range.map(
    () => new Array(selling_history.events.length),
  );

  const sales: SalePoint[] = [];

  for (let i = 0; i < selling_history.events.length; i++) {
    const event = selling_history.events[i];
    const event_day = formatDate(event.event_date_tz);
    const seat_states = _.clone(initial_seat_states);

    const changes_by_day = _.groupBy(actions_by_event[event.id] ?? [], (a) =>
      formatDate(a.created),
    );

    for (let j = 0; j < date_range.length; j++) {
      const day = date_range[j];
      if (day > event_day) {
        grid[j][i] = null;
        continue;
      } else if (day > today) {
      }
      const updates_today = changes_by_day[day] ?? [];

      for (const u of updates_today) {
        if (u.type === 'sell') {
          sales.push({
            event_num: i,
            day_num: j,
            seat_addresses: u.seat_addresses,
          });
        }
        for (const s of u.seat_addresses) {
          if (u.type === 'sell') {
            seat_states[s] = 'sold';
          } else if (u.type === 'broadcast' && seat_states[s] !== 'sold') {
            seat_states[s] = 'broadcasted';
          } else if (u.type === 'delist' && seat_states[s] === 'broadcasted') {
            seat_states[s] = 'unbroadcasted';
          }
        }
      }

      const counts = _.countBy(Object.values(seat_states), _.identity());
      const u = counts['unbroadcasted'] ?? 0;
      const b = counts['broadcasted'] ?? 0;
      const s = counts['sold'] ?? 0;

      grid[j][i] = [u, b, s];
    }
  }

  return {
    grid,
    sales,
    events: selling_history.events,
    days: date_range,
    start_date: selling_history.selling_window_start,
    end_date: selling_history.selling_window_end,
  };
};

const sellingHistorySvg =
  ({ width }: { width: number }) =>
  (
    { grid, sales, days, events, start_date, end_date }: Projection,
    max: number,
    selectedDate: UnsafeSharedValue<Date>,
  ): SVGSVGElement => {
    const day_count = grid.length;
    const event_count = grid[0]?.length || 0;
    const x_axis_height = 20;
    const y_axis_width = 40;
    const mark_height = event_count < 50 ? 4 : 3;
    const height = x_axis_height + mark_height * event_count;
    const cwidth = width - y_axis_width;

    const svg = d3.create('svg');
    svg.attr('width', width).attr('height', height);

    const x = d3
      .scaleTime()
      .domain([start_date, end_date])
      .range([y_axis_width, width]);

    const y = d3
      .scaleLinear()
      .domain([0, event_count])
      .range([0, height - x_axis_height]);

    const x_axis = d3
      .axisBottom(x)
      .ticks(width / 80)
      .tickSizeOuter(0);
    svg
      .append('g')
      .attr('transform', 'translate(0,' + (height - x_axis_height) + ')')
      .call(x_axis);

    const y_axis = d3.axisLeft(y).ticks(0);
    svg
      .append('g')
      .attr('transform', 'translate(' + y_axis_width + ',0)')
      .call(y_axis);

    const r_color_scale = d3.scaleLinear().domain([0, max]).range([100, 225]);
    const g_color_scale = d3.scaleLinear().domain([0, max]).range([100, 225]);
    const b_color_scale = d3.scaleLinear().domain([0, max]).range([150, 225]);

    const color = (triplet: CountsTriplet) => {
      const r = Math.round(r_color_scale(triplet[0]));
      const g = Math.round(g_color_scale(triplet[1]));
      const b = Math.round(b_color_scale(triplet[2]));
      return `rgba(${r},${g},${b},.7)`;
    };

    const mark_width = cwidth / durationDays(start_date, end_date);
    for (let day_num = 0; day_num < grid.length; day_num++) {
      const date = new Date(days[day_num]);
      const events = grid[day_num].filter((e) => e !== null) as CountsTriplet[];
      const event_offset = event_count - events.length;
      svg
        .append('g')
        .selectAll('rect')
        .data(events)
        .enter()
        .append('rect')
        .attr('x', x(date))
        .attr('y', (_triplet, i) => y(event_offset + i))
        .attr('width', mark_width)
        .attr('height', mark_height)
        .attr('fill', color);
    }

    svg
      .append('g')
      .selectAll('circle')
      .data(sales)
      .enter()
      .append('circle')
      .attr('cx', (s) => x(new Date(days[s.day_num])))
      .attr('cy', (s) => y(s.event_num) + mark_height / 2)
      .attr('r', mark_height / 2)
      .attr('fill', '#036');

    {
      const now = new Date();
      const future_events = events.filter((e) => e.event_date_tz > now);
      const event_offset = event_count - future_events.length;
      svg
        .append('g')
        .selectAll('line')
        .data(future_events)
        .enter()
        .append('line')
        .attr('stroke', '#eee8')
        .attr('stroke-width', mark_height)
        .attr('y1', (_e, i) => y(i + event_offset) + mark_height / 2)
        .attr('y2', (_e, i) => y(i + event_offset) + mark_height / 2)
        .attr('x1', x(new Date(days[day_count - 1])) + mark_width)
        .attr('x2', (e) => x(e.event_date_tz));
    }

    addScrubber(svg, (x_coord) => x.invert(x_coord), selectedDate, {
      vertical_rule: {
        y1: 0,
        y2: height - x_axis_height,
      },
      region: {
        x: y_axis_width,
        y: 0,
        width: cwidth,
        height: height - x_axis_height,
      },
      invert: (date) => ({ x: x(date) }),
    });

    return svg.node() as SVGSVGElement;
  };

export { toGrid, sellingHistorySvg };
