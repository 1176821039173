import h from '../../lib/react-hyperscript';
import * as _ from 'ramda';
import {
  Popover,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useAsyncFn } from 'react-use';
import * as api from '../api';
import { useState } from 'react';
import { fmt } from '../helper';
import * as amp from '../amp';
import { Adjustment, AmpPriceUpdate } from '../types';

const INDENT = '24px';

const stripScopeIds = (scope: string[]) => {
  return scope.map((s) => s.split(':')[0]).join('-');
};

const Swatch = ({
  color,
  opacity,
  stroke_width,
}: {
  color: string;
  opacity: number;
  stroke_width: number;
}) => {
  return h(Box, {
    sx: {
      display: 'inline-block',
      width: '10px',
      boxSizing: 'border-box',
      height: stroke_width * 1.5 + 'px',
      backgroundColor: color,
      opacity,
      margin: '4px 10px 2px 4px',
    },
  });
};

const AdjustmentSwatch = ({ adjustment }: { adjustment: Adjustment }) => {
  return h('div', {
    style: {
      width: '10px',
      height: '10px',
      marginRight: '7px',
      backgroundColor: amp.calibrationColor(
        amp.adjustmentCalibrationClass(adjustment),
      ),
      opacity: parseFloat(adjustment.amount) > 0 ? 0.6 : 0.6,
    },
  });
};

const Adjustments = ({ adjustments }: { adjustments: Adjustment[] }) => {
  const pro_adj = adjustments.filter((a) => a.type !== 'basic');
  const basic_adj = adjustments.filter((a) => a.type === 'basic');

  // rturn adjustments separated beneath basic and pro headers
  return h(Stack, { sx: { width: '100%' } }, [
    h(Stack, { marginLeft: INDENT }, [
      h(
        Typography,
        {
          variant: 'body1',
          mt: 1,
        },
        'Pro',
      ),
    ]),
    h(
      'table',
      {
        style: { width: 'fit-content', fontSize: '14px', whiteSpace: 'nowrap' },
      },
      [
        h(
          'tbody',
          _.sortBy((a) => a.type + a.scope.join(''), pro_adj).map((adj) =>
            h('tr', [
              h('td', [h(AdjustmentSwatch, { adjustment: adj })]),
              h('td', `${adj.type} (${stripScopeIds(adj.scope)}):`),
              h('td', { style: { textAlign: 'right', width: '80px' } }, [
                fmt.percentWithTwoDecimals(parseFloat(adj.amount)),
              ]),
            ]),
          ),
        ),
      ],
    ),
    h(Stack, { marginLeft: INDENT }, [
      h(
        Typography,
        {
          variant: 'body1',
          mt: 1,
        },
        'Basic',
      ),
    ]),
    h('table', { style: { width: 'fit-content', fontSize: '14px' } }, [
      h(
        'tbody',
        _.sortBy((a) => a.scope.join('-'), basic_adj)
          .filter((adj) => parseFloat(adj.amount) !== 0)
          .map((adj) =>
            h('tr', [
              h('td', [h(AdjustmentSwatch, { adjustment: adj })]),
              h('td', stripScopeIds(adj.scope)),
              h('td', { style: { textAlign: 'right', width: '80px' } }, [
                fmt.percentWithTwoDecimals(parseFloat(adj.amount)),
              ]),
            ]),
          ),
      ),
    ]),
  ]);
};

const PricingBreakdown = ({ data }: { data: AmpPriceUpdate }) => {
  return h(
    Box,
    data.created
      ? [
          h(
            Stack,
            {
              sx: {
                width: '100%',
                marginBottom: 1,
              },
            },
            [
              h(
                Typography,
                {
                  variant: 'body1',
                },
                [h(Swatch, amp.list_price_style), 'List price'],
              ),

              h(Stack, { marginLeft: INDENT }, [
                h(Stack, { direction: 'row', spacing: 1 }, [
                  h(
                    Typography,
                    {
                      variant: 'body2',
                      fontWeight: 'bold',
                      color: data.hit_update_max
                        ? 'error'
                        : data.hit_update_min
                        ? 'primary'
                        : 'normal',
                      title: 'model_price * amp_adjustment',
                    },
                    `$${data.price}` +
                      (data.hit_update_max
                        ? ' (ceiling hit)'
                        : data.hit_update_min
                        ? ' (floor hit)'
                        : ''),
                  ),
                  data.price !== data.desired_price
                    ? h(
                        Typography,
                        {
                          variant: 'body2',
                          fontWeight: 'bold',
                        },
                        `target: $${data.desired_price}`,
                      )
                    : null,
                ]),
              ]),
              h(Stack, { marginLeft: INDENT }, [
                h(
                  Typography,
                  { variant: 'body2', fontWeight: 'bold' },
                  `${fmt.datetime(data.created.toString())}`,
                ),
              ]),

              h(
                Typography,
                {
                  variant: 'body1',
                  mt: 1,
                },
                [h(Swatch, amp.model_price_style), 'Model price'],
              ),
              h(
                Stack,
                {
                  marginLeft: INDENT,
                },
                [
                  h(
                    Typography,
                    {
                      variant: 'body2',
                      fontWeight: 'bold',
                      title: '(erp + erp_offset) * rpf + model_price_offset',
                    },
                    data.manual_model_price
                      ? `$${data.manual_model_price} (manual)`
                      : `$${data.model_price}`,
                  ),
                  h(
                    Box,
                    {
                      sx: {
                        textDecoration: data.manual_model_price
                          ? 'line-through'
                          : '',
                      },
                    },
                    [
                      h(
                        Typography,
                        { variant: 'body2' },
                        `erp: ${data.erp && '$' + data.erp}`,
                      ),
                      h(
                        Typography,
                        { variant: 'body2' },
                        `erp_offset: $${data.erp_offset}`,
                      ),
                      h(
                        Typography,
                        { variant: 'body2' },
                        `rpf: ${
                          data.rpf && fmt.percentWithTwoDecimals(+data.rpf)
                        }`,
                      ),
                      h(
                        Typography,
                        { variant: 'body2' },
                        `model_price_offset: $${data.mp_offset}`,
                      ),
                    ],
                  ),
                ],
              ),
            ],
          ),

          h(
            Typography,
            {
              variant: 'body1',
              mt: 1,
            },
            [h(Swatch, amp.amp_adjustment_style), 'Adjustment'],
          ),
          h(Stack, { marginLeft: INDENT }, [
            h(
              Typography,
              {
                variant: 'body2',
                fontWeight: 'bold',
                title: '(1 + adj1) * (1 + adj2) * ... * (1 + adjN) - 1',
              },
              [`${fmt.percentWithTwoDecimals(+data.adjustment_total)}`],
            ),
          ]),
          h(Adjustments, { adjustments: data.adjustments as Adjustment[] }),

          data.rpf_comps_limit_config?.min_pct ||
          data.rpf_comps_limit_config?.max_pct
            ? h(Stack, { sx: { width: '100%' } }, [
                h(
                  Typography,
                  {
                    variant: 'h6',
                    mt: 1,
                  },
                  'RPF-comp limits',
                ),
                h(
                  Typography,
                  {
                    variant: 'body2',
                  },
                  `Min: ${
                    data.rpf_comps_limit_config?.min_pct
                      ? fmt.percentWithTwoDecimals(
                          +data.rpf_comps_limit_config.min_pct,
                        )
                      : 'N/A'
                  }`,
                ),
                h(
                  Typography,
                  {
                    variant: 'body2',
                  },
                  `Max: ${
                    data.rpf_comps_limit_config?.max_pct
                      ? fmt.percentWithTwoDecimals(
                          +data.rpf_comps_limit_config.max_pct,
                        )
                      : 'N/A'
                  }`,
                ),
                h(
                  Typography,
                  {
                    variant: 'body2',
                  },
                  `Max comp: ${data.rpf_comps_limit_config?.max_comp || 'N/A'}`,
                ),
              ])
            : null,
        ]
      : [
          h(
            Typography,
            {
              variant: 'h6',
              fontWeight: 'bold',
            },
            'No pricing adjustment history found.',
          ),
        ],
  );
};

function InventoryPricingAdjustment({
  inv_id,
  label,
}: {
  inv_id: number;
  label?: any | HTMLElement;
}) {
  const [state, doFetch] = useAsyncFn(api.getInventoryAdjustment);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!state.loading && !state.value)
      doFetch(inv_id).catch(() => {
        console.error(state.error);
      });

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return label
    ? h('span', [
        h(
          Box,
          {
            component: 'span',
            sx: { cursor: 'pointer' },
            onClick: handleClick,
          },
          label,
        ),

        h(
          Popover,
          {
            open: Boolean(anchorEl),
            anchorEl,
            onClose: handleClose,
            anchorOrigin: {
              vertical: 'center',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'center',
              horizontal: 'right',
            },
          },
          [
            h(
              Card,
              {
                sx: {
                  minHeight: '200px',
                  overflowY: 'scroll',
                  minWidth: '300px',
                },
              },
              [
                h(
                  CardContent,
                  state.value
                    ? [h(PricingBreakdown, { data: state.value })]
                    : [h(CircularProgress)],
                ),
              ],
            ),
          ],
        ),
      ])
    : null;
}

export default InventoryPricingAdjustment;
export { PricingBreakdown };
