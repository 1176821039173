import h from '../../lib/react-hyperscript';
import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState, useMemo } from 'react';

import { innerHeight } from '../util/gfx';
import { priceHistorySvg } from '../gfx/charts';
import { adjustmentHistorySvg } from '../gfx/charts';
import { AmpPricingHistory, AmpPriceUpdate } from '../types';
import { PricingBreakdown } from './InventoryPricingAdjustment';
import { UnsafeSharedValue } from '../util/shared_value';

const IPHChart = ({
  data,
  selectedDate,
}: {
  data: AmpPricingHistory;
  selectedDate: UnsafeSharedValue<Date>;
}) => {
  const ref = useRef(null);
  const width = 400;
  const height = 250;

  useEffect(() => {
    if (ref.current) {
      const { width, height } = innerHeight(ref.current);
      (ref.current as HTMLElement).replaceChildren();
      const svg = priceHistorySvg({
        height,
        width,
      })(data, selectedDate);
      (ref.current as HTMLElement).appendChild(svg);
    }
  }, []);

  return h('div.price-history-chart', {
    id: 'price-history-chart-123', //+ data.price_updates[0].inventory_id,
    ref,
    style: {
      height,
      width,
    },
  });
};

const IPAChart = ({
  data,
  selectedDate,
}: {
  data: AmpPricingHistory;
  selectedDate: UnsafeSharedValue<Date>;
}) => {
  const ref = useRef(null);
  const width = 400;
  const height = 250;

  useEffect(() => {
    if (ref.current) {
      const { width, height } = innerHeight(ref.current);
      (ref.current as HTMLElement).replaceChildren();
      const svg = adjustmentHistorySvg({
        height,
        width,
      })(data.price_updates, selectedDate);
      (ref.current as HTMLElement).appendChild(svg);
    }
  }, []);

  return h('div.price-history-chart', {
    id: 'price-history-chart-123', //+ data.price_updates[0].inventory_id,
    ref,
    style: {
      height,
      width,
    },
  });
};

const lastPriceUpdate = (data: AmpPricingHistory) => {
  return data.price_updates[data.price_updates.length - 1] ?? null;
};

const getPriceUpdate = (data: AmpPricingHistory, date: Date) => {
  return (
    data.price_updates.find((pu) => pu.created.getTime() === date.getTime()) ??
    lastPriceUpdate(data)
  );
};

// Requires at least 1 price_update record; should not be called otherwise
const IPHPanel = ({ data }: { data: AmpPricingHistory }) => {
  const selectedDate = useMemo(
    () => new UnsafeSharedValue(lastPriceUpdate(data)?.created),
    [data],
  );
  const [spuVal, setSpuVal] = useState(lastPriceUpdate(data).created);

  useEffect(() => {
    selectedDate.subscribe(setSpuVal);
  }, [selectedDate]);

  return selectedDate
    ? h(Stack, { alignItems: 'center' }, [
        h(
          Typography,
          { variant: 'h6', gutterBottom: true },
          data.inventory.description,
        ),
        h(Stack, { direction: 'row', spacing: 4 }, [
          h(Stack, { spacing: 2 }, [
            h(Stack, { alignItems: 'center', spacing: 1 }, [
              h(
                Typography,
                { variant: 'body1', gutterBottom: true },
                'Price History',
              ),
              h(IPHChart, { data, selectedDate }),
            ]),
            h(Stack, { alignItems: 'center', spacing: 1 }, [
              h(
                Typography,
                { variant: 'body1', gutterBottom: true },
                'Adjustment History',
              ),
              h(IPAChart, { data, selectedDate }),
            ]),
          ]),
          h(PricingBreakdown, { data: getPriceUpdate(data, spuVal) }),
        ]),
      ])
    : h(Box, 'No pricing data found');
};

export default IPHPanel;
