import h from '../../lib/react-hyperscript';
import { Stack, IconButton } from '@mui/material';
import { useState } from 'react';
import { purple } from '@mui/material/colors';

import * as api from '../api';
import NoteDetailsDialog from './NoteDetailsDialog';
import { Subject as SubjectIcon } from '@mui/icons-material';
import { useBaseData } from '../base_data';

const PackageDrillDown = ({
  label,
  package_id,
}: {
  label: string;
  package_id: number;
}) => {
  const {
    baseData: { isAnyPackageNotes, recordPackageNoteStatus },
  } = useBaseData();
  const [is_dialog_open, setDialogOpen] = useState(false);

  return h('span', [
    h(
      Stack,
      {
        direction: 'row',
        alignItems: 'center',
        sx: {
          cursor: 'pointer',
          borderRadius: 999,
          backgroundColor: purple[50],
          padding: '0 7px 0 0',
          color: 'secondary.dark',
        },
        //        onClick: handleClick,
      },
      [
        // h(ExpandCircleDown, {
        //   color: 'secondary',
        //   sx: { verticalAlign: 'baseline', fontSize: '0.8em' },
        // }),

        h(IconButton, {
          sx: { margin: '-3px 0' },
          size: 'small',
          onClick: (e) => {
            e.stopPropagation();
            setDialogOpen(true);
          },
          children: h(SubjectIcon, {
            color: isAnyPackageNotes(package_id) ? 'info' : 'disabled',
            sx: { fontSize: 18 },
          }),
        }),
        label,
      ],
    ),
    // h(
    //   Popover,
    //   {
    //     open: Boolean(anchorEl),
    //     anchorEl,
    //     onClose: handleClose,
    //     anchorOrigin: {
    //       vertical: 'bottom',
    //       horizontal: 'left',
    //     },
    //   },
    //   [
    //     h(ClientCard, {
    //       client_summary: state.value,
    //       error: state.error,
    //     }),
    //   ],
    // ),
    is_dialog_open
      ? h(NoteDetailsDialog, {
          cur_header: '',
          entry_desc: '',
          getNoteAbstractionFn: () =>
            api.getNoteAbstract({
              row: { package_id },
              api_key_field: 'package_id',
              ref_source: 'ticket_package',
              ref_usage: 'general',
            }),
          saveNoteBodyFn: async (new_value) => {
            await api.updateNoteDetail({
              row: { package_id },
              new_value,
              api_key_field: 'package_id',
              ref_source: 'ticket_package',
              ref_usage: 'general',
            });
            const has_notes = await api.isAnyNotes(
              package_id,
              'ticket_package',
            );
            recordPackageNoteStatus(package_id, has_notes);
          },
          saveNoteHeaderFn: async (new_value) => {
            await api.updateNoteHeader({
              row: { package_id },
              new_value,
              api_key_field: 'package_id',
              ref_source: 'ticket_package',
              ref_usage: 'general',
            });
            const has_notes = await api.isAnyNotes(
              package_id,
              'ticket_package',
            );
            recordPackageNoteStatus(package_id, has_notes);
          },
          getDataFn: () =>
            api.getNoteDetail({
              row: { package_id },
              api_key_field: 'package_id',
              ref_source: 'ticket_package',
              ref_usage: 'general',
            }),
          onClose: () => setDialogOpen(false),
        })
      : null,
  ]);
};

export default PackageDrillDown;
export { PackageDrillDown };
