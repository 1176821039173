import { type BaseDataProvider } from '../../../common/base_data';
import { daysBetween } from '../../../common/util';
import {
  format,
  QuickFilterSpec,
  PercentColorCell,
} from '../../../common/components';
import { SellthruWidget } from '../../../common/components/SellthruWidget';
import { GridColDef } from '@mui/x-data-grid-pro';
import h from '../../../lib/react-hyperscript';
import * as _ from 'ramda';
import { LeagueId, AggregationLevel } from '../../../common/types';
import {
  FetchingPopover,
  SellingHistoryPanel,
} from '../../../common/components';
import * as api from '../../../common/api';

const ldws = (row: {
  daily_listed_counts: number[];
  daily_sold_counts: number[];
}) => {
  if (!row.daily_listed_counts || !row.daily_sold_counts) return null;
  // find the index of the first non zero value in the sold counts
  const first_sold = row.daily_sold_counts.findIndex((x) => x > 0);
  // sum all the listed counts before the first sold
  const ldws = row.daily_listed_counts
    .slice(0, first_sold)
    .reduce((acc, x) => acc + x, 0);

  return Math.round(ldws);
};

const ldpsSparklineData =
  (opts: { days: number; avg_window: number }) => (row: any) => {
    if (!row.daily_listed_counts || !row.daily_sold_counts) {
      return {
        max: 100,
        min: 0,
        points: [],
      };
    }

    const dlc = row.daily_listed_counts as number[];
    const dsc = row.daily_sold_counts as number[];

    const dlc_smooth = dlc.map((_x, i) => {
      const window = Math.min(opts.avg_window, dlc.length - i);
      const sum = dlc
        .slice(i, i + window)
        .reduce((acc: number, x: number | null) => acc + (x ?? 0), 0);
      return sum / window;
    });

    const dsc_smooth = dsc.map((_x, i) => {
      const window = Math.min(opts.avg_window, dsc.length - i);
      const sum = dsc
        .slice(i, i + window)
        .reduce((acc: number, x: number | null) => acc + (x ?? 0), 0);
      return sum / window;
    });

    const ldps: number[] = [];
    for (let i = 0; i < dlc_smooth.length; i += 1) {
      const listed_days = dlc_smooth
        .slice(i, i + opts.days)
        .reduce((acc, x) => acc + x, 0);
      const sold_days = dsc_smooth
        .slice(i, i + opts.days)
        .reduce((acc, x) => acc + x, 0);
      ldps.push(
        Math.round(
          sold_days ? listed_days / sold_days : listed_days ? Infinity : 0,
        ),
      );
    }

    // if (row.id === 16283) {
    //   console.log(
    //     'Row ID: ' + row.id,
    //     opts,
    //     row.daily_listed_counts,
    //     row.daily_sold_counts,
    //     ldps,
    //   );
    // }

    return {
      max: 100,
      min: 0,
      points: ldps
        .map((x) => ({
          value: x,
          opacity: 1,
        }))
        .reverse(),
    };
  };
const dps_column_spec = (
  window_days: number,
  level: AggregationLevel,
): GridColDef => ({
  field: `dps_${window_days}`,
  flex: 1,
  minWidth: 120,
  valueGetter: ({ row }: { row: { [key: string]: any } }) =>
    row.listed + row.sold === 0
      ? null
      : ldpsSparklineData({ days: 1, avg_window: window_days })(row),
  sortComparator: (a: any, b: any) => {
    if (a === null && b === null) return 0;
    if (a === null) return -1;
    if (b === null) return 1;

    if (a.points.length === 0 && b.points.length === 0) return 0;
    if (a.points.length === 0) return -1;
    if (b.points.length === 0) return 1;
    return (
      a.points[a.points.length - 1].value - b.points[b.points.length - 1].value
    );
  },

  renderCell: ({ value, row }: any) => {
    if (!value) return null;
    return h(
      FetchingPopover,
      {
        fetchFn: api.getSellingHistory,
        args: [level, row.id, row.season],
        renderPopup: (data: any) => h(SellingHistoryPanel, { data, level }),
      },
      [
        row.daily_listed_counts
          ? h(SellthruWidget, {
              data: value,
              points_shown: 30,
            })
          : null,
      ],
    );
  },
});

const column_specs = (level: AggregationLevel): GridColDef[] => [
  dps_column_spec(5, level),
  dps_column_spec(10, level),
  {
    field: 'calibration_amount',
    headerName: 'cal',
    valueFormatter: format.percent,
    width: 80,
    type: 'number',
  },
  {
    field: 'dsc',
    headerName: 'dsc',
    description: 'Days Since Calibration',
    width: 50,
    valueGetter: ({ row }) =>
      row.last_adjustment ? daysBetween(row.last_adjustment, new Date()) : null,
    valueFormatter: ({ value }) => (_.isNil(value) ? null : value.toFixed(0)),
    type: 'number',
  },
  {
    field: 'dws',
    align: 'right',
    description: 'Days Without Sale',
    width: 50,
    valueGetter: ({ row }) =>
      row.last_sold ?? row.first_listed
        ? daysBetween(row.last_sold ?? row.first_listed, new Date())
        : null,
    valueFormatter: ({ value }) => (_.isNil(value) ? null : value.toFixed(0)),
    type: 'number',
  },
  {
    field: 'ldws',
    headerName: 'ldws',
    description:
      'Listed Days Without Sale (NB: weighted and only counting last 30 days)',
    width: 50,
    valueGetter: ({ row }) => ldws(row),
    align: 'right',
    type: 'number',
  },
  {
    field: 'last_sold',
    align: 'right',
    valueFormatter: format.date,
    type: 'date',
    valueGetter: ({ value }) => (value ? new Date(value) : null),
  },

  {
    field: 'sell_thru',
    description: 'Sell thru over all time',
    headerName: 'sell_thru',
    align: 'right',
    type: 'number',
    valueGetter: ({ row }) =>
      row.pct_sold / (row.pct_listed + row.pct_sold) || null,
    renderCell: PercentColorCell,
    width: 60,
  },
  {
    field: 'tickets',
    align: 'right',
    type: 'number',
    width: 60,
  },
  {
    field: 'pct_listed',
    headerName: 'listed%',
    align: 'right',
    type: 'number',
    valueFormatter: format.percent,
    width: 60,
  },
  {
    field: 'pct_sold',
    headerName: 'sold%',
    align: 'right',
    type: 'number',
    valueFormatter: format.percent,
    width: 60,
  },
  {
    field: 'pct_delisted',
    headerName: 'delisted%',
    align: 'right',
    type: 'number',
    valueFormatter: format.percent,
    width: 60,
  },

  {
    field: 'sale_score_avg',
    headerName: 'score',
    description:
      'Delta btwn sale price and model price when listed. Only sold inventory',
    align: 'right',
    renderCell: PercentColorCell,
    width: 80,
    type: 'number',
  },
  {
    field: 'unsold_model_diff_avg',
    headerName: 'unsold_mΔ',
    description: 'Unsold Model Comp',
    align: 'right',
    valueFormatter: format.percent,
    width: 80,
    type: 'number',
  },
  {
    field: 'sold_model_diff_avg',
    headerName: 'sold_mΔ',
    description: 'Sold Model Comp',
    align: 'right',
    width: 80,
    valueFormatter: format.percent,
    type: 'number',
  },
  {
    field: 'list_change_avg',
    headerName: 'list±',
    description: 'List Price Change',
    align: 'right',
    valueFormatter: format.percent,
    width: 80,
    type: 'number',
  },
  {
    field: 'listed_value',
    headerName: '$listed',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'sold_value',
    headerName: '$sold',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'unlisted_value',
    headerName: '$unlisted',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'delisted_value',
    headerName: '$delisted',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
];

const column_groups = [
  {
    groupId: 'Velocity',
    children: [
      { field: 'dps_5' },
      { field: 'dps_10' },
      { field: 'calibration_amount' },
      { field: 'dsc' },
      { field: 'ldws' },
      { field: 'dws' },
      { field: 'last_sold' },
    ],
  },

  {
    groupId: 'Volume',
    children: [
      { field: 'sell_thru' },
      { field: 'tickets' },
      { field: 'pct_listed' },
      { field: 'pct_sold' },
      { field: 'pct_delisted' },
    ],
  },

  {
    groupId: 'Earnings',
    children: [
      { field: 'sale_score_avg' },
      { field: 'unsold_model_diff_avg' },
      { field: 'sold_model_diff_avg' },
      { field: 'sold_value' },
      { field: 'unlisted_value' },
      { field: 'delisted_value' },
      { field: 'listed_value' },
      { field: 'list_change_avg' },
    ],
  },
];

const buildFilterSpecs = (baseData: BaseDataProvider) => {
  const filter_specs: QuickFilterSpec<number | LeagueId>[] = [
    {
      id: 'season',
      label: 'Season',
      optionValues: _.map(
        (start_year) => ({
          value: start_year,
          label: start_year.toString(),
        }),
        baseData.getSeasonStartYears(),
      ),
    },
    {
      id: 'league',
      label: 'League',
      optionValues: _.map(
        (league_id) => ({ label: league_id, value: league_id }),
        baseData.getLeagueIds(),
      ),
    },
    { id: 'is_amp', label: 'AMP?', yesNoAny: true },
  ];
  return filter_specs;
};

export { column_specs, column_groups, buildFilterSpecs, dps_column_spec };
