import * as d3 from 'd3';
import { AmpPriceUpdate } from '../../types';
import * as amp from '../../amp';
import { UnsafeSharedValue } from '../../util/shared_value';
import { addScrubber } from '.';
import { stackedSeries } from './data';

const adjustmentHistorySvg =
  ({ width, height }: { width: number; height: number }) =>
  (rawData: AmpPriceUpdate[], selectedDate: UnsafeSharedValue<Date>) => {
    const adjustments = rawData.flatMap((d) =>
      d.adjustments.map((a) => ({
        created: d.created,
        amount: parseFloat(a.amount),
        class: amp.adjustmentCalibrationClass(a),
      })),
    );

    // Set up the SVG canvas
    const x_axis_height = 20;
    const y_axis_width = 40;
    const cheight = height - x_axis_height;

    const svg = d3.create('svg');
    svg.attr('width', width).attr('height', height).append('g');

    // We don't want a new color for every possible scope permutation. This means,
    // theoretically, tow calibrations could be represented by a single color. However,
    // the tabular display will break it all the way down.

    const spos = stackedSeries<Date>(
      'class',
      'created',
      'amount',
      amp.calibration_classes,
    )(adjustments.filter((a) => a.amount > 0));

    const sneg = stackedSeries<Date>(
      'class',
      'created',
      'amount',
      amp.calibration_classes,
    )(adjustments.filter((a) => a.amount < 0));

    // Create x and y scales
    const x = d3
      .scaleTime()
      .domain(d3.extent(rawData, (d) => d.created) as [Date, Date])
      .range([y_axis_width, width]);

    const y = d3
      .scaleLinear()
      .domain([
        Math.min(
          d3.min(sneg, (d) => d.limit) as number,
          d3.min(rawData, (d) => d.adjustment_total) as number,
        ) - 0.1,
        Math.max(
          d3.max(spos, (d) => d.limit) as number,
          d3.max(rawData, (d) => d.adjustment_total) as number,
        ) + 0.1,
      ])
      .range([cheight, 0]);

    const append_area = (data: typeof spos, opts: { opacity: number }) => {
      svg
        .append('g')
        .selectAll('.area')
        .data(data)
        .enter()
        .append('path')
        .attr('class', 'area')
        .attr('opacity', opts.opacity)
        .attr('fill', (d) => amp.calibrationColor(d.name) as string)
        .attr('d', ({ values }) =>
          d3
            .area<typeof values[0]>()
            .x((d) => x(d.index))
            .y0((d) => y(d.span[0]))
            .y1((d) => y(d.span[1]))(values),
        );
    };

    append_area(spos, { opacity: 0.5 });
    append_area(sneg, { opacity: 0.5 });

    svg
      .append('line')
      .attr('stroke', 'white')
      .attr('stroke-width', 1.5)
      .style('opacity', 1)
      .attr('x1', y_axis_width)
      .attr('x2', width)
      .attr('y1', y(0))
      .attr('y2', y(0));

    svg
      .append('path')
      .datum(rawData)
      .attr('fill', 'none')
      .attr('stroke', amp.amp_adjustment_style.color)
      .attr('stroke-width', amp.amp_adjustment_style.stroke_width)
      .attr('stroke-opacity', amp.amp_adjustment_style.opacity)
      .attr(
        'd',
        d3
          .line<AmpPriceUpdate>()
          .x((d) => {
            return x(d.created);
          })
          .y((d) => {
            return y(d.adjustment_total);
          }),
      );

    // Append x-axis
    svg
      .append('g')
      .attr('transform', `translate(0,${cheight})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(width / 80)
          .tickSizeOuter(0),
      );

    // Append y-axis
    svg
      .append('g')
      .attr('transform', 'translate(' + y_axis_width + ',0)')
      .call(
        d3
          .axisLeft(y)
          .ticks(height / 40)
          .tickFormat(d3.format('.0%')),
      );

    const getClosestPriceUpdate = (date: Date) => {
      return rawData.reduce((prev: AmpPriceUpdate, curr: AmpPriceUpdate) =>
        Math.abs(curr.created.getTime() - date.getTime()) <
        Math.abs(prev.created.getTime() - date.getTime())
          ? curr
          : prev,
      );
    };

    addScrubber(
      svg,
      (x_coord) => getClosestPriceUpdate(x.invert(x_coord)).created,
      selectedDate,
      {
        vertical_rule: {
          y1: 0,
          y2: height - x_axis_height,
        },
        region: {
          x: y_axis_width,
          y: 0,
          width: width - y_axis_width,
          height: height - x_axis_height,
        },
        invert: (date) => ({ x: x(date) }),
      },
    );

    return svg.node() as SVGSVGElement;
  };

export { adjustmentHistorySvg };
