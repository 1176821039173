import { useBaseData, BaseDataProvider } from '../../../common/base_data';
import { PageDataGrid, QuickFilterSpec } from '../../../common/components';
import { GridColDef } from '@mui/x-data-grid-pro';

import DataGridEntryLink from '../../../common/components/DataGridEntryLink';
import { LeagueId } from '../../../common/types';

import * as agg from './aggregatePricingColumns';

const column_specs: GridColDef[] = [
  {
    field: 'team',
    width: 100,
    renderCell: (params) =>
      DataGridEntryLink(
        { id: '`' + (params.row.season - 2000) + '-' + params.row.team },
        `/pricing/events?filter.01.team=equals%2C${params.row.team}&filter.02.season=equals%2C${params.row.season}&filterOp=and&v=2`,
      ),
  },
  { field: 'id', headerName: 'team_id', width: 75 },
  { field: 'season', width: 75 },
  { field: 'league', width: 75 },
  {
    field: 'is_amp',
    headerName: 'amp',
    width: 75,
    type: 'boolean',
  },

  ...agg.column_specs('team'),
];

const column_groups = [
  {
    groupId: 'Team',
    children: [
      { field: 'team' },
      { field: 'id' },
      { field: 'season' },
      { field: 'league' },
      { field: 'is_amp' },
    ],
  },

  ...agg.column_groups,
];

const buildFilterSpecs = (baseData: BaseDataProvider) => {
  const filter_specs: QuickFilterSpec<number | LeagueId>[] = [
    ...agg.buildFilterSpecs(baseData),

    { id: 'is_shown', label: 'Showing?', yesNoAny: true },
  ];
  return filter_specs;
};

export default function PackagePricingDataGrid({ rows }: { rows: any[] }) {
  const { baseData } = useBaseData();
  rows = baseData.hydrateTeams(rows);
  return PageDataGrid({
    id: 'event_pricing',
    getRowId: (row) => row.id + '-' + row.is_amp + '-' + row.season,
    rows: rows,
    column_specs,
    column_groups,
    filter_specs: buildFilterSpecs(baseData),
  });
}
