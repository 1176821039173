import * as d3 from 'd3';
import { UnsafeSharedValue } from '../../util/shared_value';
import { assertIsDefined } from '../../util';

type Mapper<T> = (x: number, y: number) => T;
type Inverter<T> = (datum: T) => { x?: number; y?: number };

type ScrubberOpts<T> = {
  vertical_rule?: {
    y1: number;
    y2: number;
  };
  horizaontal_rule?: {
    x1: number;
    x2: number;
  };
  region?: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  invert?: Inverter<T>;
};

const addScrubber = <T>(
  svg: d3.Selection<SVGSVGElement, undefined, null, undefined>,
  map: Mapper<T>,
  selection: UnsafeSharedValue<T>,
  opts: ScrubberOpts<T> = {},
) => {
  const vert = opts.vertical_rule ?? {
    y1: 0,
    y2: svg?.node()?.getBBox().height,
  };

  assertIsDefined(vert.y2, 'vertical rule does not have a height');

  const region =
    opts.region ??
    ((bbox: DOMRect | undefined) => bbox)(svg?.node()?.getBBox());

  assertIsDefined(region);

  const is_in_region = (x: number, y?: number) =>
    x > region.x &&
    x <= region.x + region.width &&
    (y === undefined || y > region.y) &&
    (y === undefined || y <= region.y + region.height);

  assertIsDefined(region, 'lined does not have a height');

  const vertical_rule = svg
    .append('line')
    .attr('stroke', 'black')
    .attr('stroke-width', 1)
    .attr('stroke-dasharray', '3 4')
    .style('opacity', 0) // Initially hidden
    .attr('y1', vert.y1)
    .attr('y2', vert.y2);

  selection.subscribe((datum: T) => {
    if (opts.invert) {
      const { x, y } = opts.invert(datum);
      // For now, displaying a vertical rule is assumed, but this could
      // be made more flexible
      assertIsDefined(x, 'inverter did not return an x position');

      if (!is_in_region(x, y)) {
        vertical_rule.style('opacity', 0);
        return;
      }

      vertical_rule.style('opacity', 1).attr('x1', x).attr('x2', x);
    }
  });

  svg.on('mousemove', (event: MouseEvent) => {
    const [mouse_x, mouse_y] = d3.pointer(event, svg.node());
    const closest_data = map(mouse_x, mouse_y);

    // Ensure the line stays within bounds
    if (is_in_region(mouse_x, mouse_y)) {
      if (closest_data) selection.set(map(mouse_x, mouse_y));
    }
  });
};

export { addScrubber, type ScrubberOpts, type Mapper, type Inverter };
