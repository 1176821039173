import { useEffect, useRef, useMemo, useState } from 'react';

import h from '../../lib/react-hyperscript';
import { Box, Stack, Typography } from '@mui/material';

import {
  toGrid,
  sellingHistorySvg,
  packageCalibrationHistorySvg,
} from '../gfx/charts';
import { SellingHistory, AggregationLevel } from '../types';
import { UnsafeSharedValue } from '../util/shared_value';
import { SellingHistoryBreakdown } from '.';
import * as amp from '../amp';

const SHChart = ({
  data,
  selectedDate,
}: {
  data: SellingHistory;
  selectedDate: UnsafeSharedValue<Date>;
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      (ref.current as HTMLElement).replaceChildren();
      const svg = sellingHistorySvg({
        width: 600,
      })(toGrid(data), data.seat_addresses.length, selectedDate);
      (ref.current as HTMLElement).appendChild(svg);
    }
  }, []);

  return h('div.selling-history-chart', {
    id: 'selling-history-chart-123', //+ data.price_updates[0].inventory_id,
    ref,
  });
};

const PCHChart = ({
  data,
  selectedDate,
}: {
  data: SellingHistory;
  selectedDate: UnsafeSharedValue<Date>;
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      (ref.current as HTMLElement).replaceChildren();
      const svg = packageCalibrationHistorySvg({
        width: 600,
        height: 200,
      })(
        {
          calibrations: data.calibrations,
          from: data.selling_window_start,
          to:
            new Date() > data.selling_window_end
              ? data.selling_window_end
              : new Date(),
        },
        selectedDate,
      );
      (ref.current as HTMLElement).appendChild(svg);
    }
  }, []);

  return h('div.package-calibration-history-chart', {
    id: 'package-calibration-history-chart-123',
    ref,
  });
};

// Requires at least 1 price_update record; should not be called otherwise
const SHPanel = ({
  data,
  level,
}: {
  data: SellingHistory;
  level: AggregationLevel;
}) => {
  const selectedDate = useMemo(() => {
    return new UnsafeSharedValue(new Date());
  }, [data]);

  const [spuVal, setSpuVal] = useState(new Date());

  useEffect(() => {
    selectedDate.subscribe(setSpuVal);
    setTimeout(() => {
      const cals = amp.filterBasicPackageCalibrations(data.calibrations);
      const lastDate = cals?.[cals.length - 1]?.created ?? new Date();
      selectedDate.set(lastDate);
    }, 50);
  }, [selectedDate]);

  return data
    ? h(Stack, { alignItems: 'center' }, [
        h(Typography, { variant: 'h6', gutterBottom: true }, data.description),
        h(Stack, { direction: 'row', spacing: 4 }, [
          h(Stack, { spacing: 2 }, [
            h(Stack, { alignItems: 'center', spacing: 1 }, [
              h(
                Typography,
                { variant: 'body1', gutterBottom: true },
                'Selling History',
              ),
              h(SHChart, { data, selectedDate }),
            ]),
            h(Stack, { alignItems: 'center', spacing: 1 }, [
              h(
                Typography,
                { variant: 'body1', gutterBottom: true },
                'Calibration History',
              ),
              h(PCHChart, { data, selectedDate }),
            ]),
          ]),
          h(SellingHistoryBreakdown, { data, level, selectedDate: spuVal }),
        ]),
      ])
    : h(Box, 'No pricing data found');
};

export default SHPanel;
