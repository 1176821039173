import h from '../../lib/react-hyperscript';
import {
  Card,
  Box,
  Link,
  CardContent,
  Chip,
  Divider,
  CircularProgress,
  Button,
  TextField,
  Stack,
} from '@mui/material';
import * as _ from 'ramda';

import { ClientSummary, LeagueId } from '../types';
import LeagueIcon from './LeagueIcon';

import { Email, Phone, Sell, Tag, Person } from '@mui/icons-material';
import { prettyUsNumber } from '../util/phone';
import { useState } from 'react';

export type ClientCardProps = {
  client_summary?: ClientSummary;
  error?: Error;
  is_editable?: boolean;
  onSave?: (payload: {
    phone: string;
    email: string;
    first_name: string;
    last_name: string;
  }) => void;
};

function formatPhoneNumber(phoneString: string) {
  const value = phoneString.replace(/[^0-9]/g, '').slice(0, 10);
  const matches = value.match(/(\d{0,3})(\d{0,3})?(\d{0,4})?/);

  if (!matches) {
    return { value, formatted: '' };
  }

  let result = '';
  if (matches[1].length === 3) {
    result += `(${matches[1]}) `;
  } else if (matches[1].length > 0) {
    result += `(${matches[1]}`;
  }

  if (matches[2]?.length === 3) {
    result += `${matches[2]}-`;
  } else if (matches[2]?.length > 0) {
    result += matches[2];
  }

  if (matches[3]?.length === 4) {
    result += `${matches[3]}`;
  } else if (matches[3]?.length > 0) {
    result += matches[3];
  }

  return { value, formatted: result };
}

function ClientCard({ client_summary, is_editable, onSave }: ClientCardProps) {
  const leaguesByTeam = client_summary
    ? _.toPairs(_.groupBy(_.prop('league'), client_summary.teams))
    : [];

  const [is_edit, setEditMode] = useState(false);

  const [phone_input, setPhoneInput] = useState(
    prettyUsNumber(client_summary?.phone ?? ''),
  );
  const [email_input, setEmailInput] = useState(client_summary?.email);
  const [first_name_input, setFirstNameInput] = useState(
    client_summary?.first_name,
  );
  const [last_name_input, setLastNameInput] = useState(
    client_summary?.last_name,
  );

  return h(Card, [
    h(
      CardContent,
      client_summary
        ? [
            h(
              Box,
              {
                sx: {
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                },
              },
              leaguesByTeam.map(([league, teams]) =>
                h(
                  Box,
                  {
                    sx: { display: 'inline-flex', alignItems: 'center', mr: 1 },
                  },
                  [
                    h(LeagueIcon, {
                      key: league,
                      league: league as LeagueId,
                    }),
                    teams.map((t) =>
                      h(Chip, {
                        size: 'small',
                        key: t.abbrev,
                        label: t.abbrev,
                        sx: { mr: '2px' },
                      }),
                    ),
                  ],
                ),
              ),
            ),
            h(Divider, { sx: { mt: 2, mb: 2 } }),
            h(
              Box,
              {
                sx: { display: 'flex', justifyContent: 'space-between' },
              },
              [
                h(Box, [
                  h(Box, { sx: { display: 'flex', alignItems: 'center' } }, [
                    h(Tag, { sx: { mr: 1 } }),
                    h(Box, client_summary.id),
                  ]),

                  h(
                    Box,
                    {
                      sx: { display: 'flex', alignItems: 'center', space: 1 },
                    },
                    [
                      h(Person, { sx: { mr: 1 } }),
                      is_edit
                        ? h(Stack, { direction: 'row' }, [
                            h(TextField, {
                              disabled: !is_edit,
                              margin: 'normal',
                              required: true,
                              size: 'small',
                              fullWidth: true,
                              id: 'first_name',
                              label: 'First Name',
                              name: 'first_name',
                              value: first_name_input,
                              onChange: (e) => {
                                setFirstNameInput(e.target.value);
                              },
                            }),
                            h(TextField, {
                              disabled: !is_edit,
                              margin: 'normal',
                              required: true,
                              size: 'small',
                              fullWidth: true,
                              id: 'last_name',
                              label: 'Last Name',
                              name: 'last_name',
                              value: last_name_input,
                              onChange: (e) => {
                                setLastNameInput(e.target.value);
                              },
                            }),
                          ])
                        : h(
                            Box,
                            `${client_summary.first_name} ${client_summary.last_name}`,
                          ),
                    ],
                  ),

                  h(Box, { sx: { display: 'flex', alignItems: 'center' } }, [
                    h(Email, { sx: { mr: 1 } }),
                    is_edit
                      ? h(TextField, {
                          disabled: !is_edit,
                          margin: 'normal',
                          required: true,
                          size: 'small',
                          fullWidth: true,
                          id: 'email',
                          label: 'Email',
                          name: 'email',
                          value: email_input,
                          onChange: (e) => {
                            setEmailInput(e.target.value);
                          },
                        })
                      : h(Box, client_summary.email),
                  ]),

                  h(Box, { sx: { display: 'flex', alignItems: 'center' } }, [
                    h(Phone, { sx: { mr: 1 } }),
                    is_edit
                      ? h(TextField, {
                          disabled: !is_edit,
                          margin: 'normal',
                          required: true,
                          prefix: '+1',
                          size: 'small',
                          fullWidth: true,
                          id: 'phone',
                          label: 'Phone',
                          name: 'phone',
                          value: prettyUsNumber(phone_input ?? ''),
                          onChange: (e) => {
                            setPhoneInput(e.target.value);
                          },
                        })
                      : h(
                          Box,
                          client_summary.phone
                            ? prettyUsNumber(client_summary.phone)
                            : '',
                        ),
                  ]),

                  h(Box, { sx: { display: 'flex', alignItems: 'center' } }, [
                    h(Sell, { sx: { mr: 1 } }),
                    h(Box, client_summary.client_tag),
                  ]),
                  is_editable
                    ? h(
                        Box,
                        {
                          sx: {
                            display: 'flex',
                            justifyContent: 'start',
                            gap: 1,
                            marginTop: 2,
                          },
                        },
                        !is_edit
                          ? [
                              h(
                                Button,
                                {
                                  key: 'edit',
                                  sx: { ml: 2 },
                                  onClick: () => setEditMode(true),
                                },
                                'Change',
                              ),
                            ]
                          : [
                              h(
                                Button,
                                {
                                  key: 'cancel',
                                  variant: 'outlined',
                                  size: 'small',
                                  sx: { width: '100%' },
                                  onClick: () => {
                                    setEditMode(false);
                                    setPhoneInput(client_summary.phone ?? '');
                                    setEmailInput(client_summary.email);
                                  },
                                },
                                'Cancel',
                              ),
                              h(
                                Button,
                                {
                                  key: 'submit',
                                  variant: 'contained',
                                  size: 'small',
                                  sx: { width: '100%' },
                                  onClick: () => {
                                    setEditMode(false);
                                    onSave?.({
                                      phone: phone_input
                                        ? `+1${
                                            formatPhoneNumber(phone_input).value
                                          }`
                                        : '',
                                      email: email_input ?? '',
                                      first_name: first_name_input ?? '',
                                      last_name: last_name_input ?? '',
                                    });
                                  },
                                },
                                'Save',
                              ),
                            ],
                      )
                    : null,
                ]),
              ],
            ),
            h(Divider, { sx: { mt: 2, mb: 2 } }),
            h(
              Box,
              {
                sx: {
                  mt: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                },
              },
              [
                ...client_summary.links.map((l) =>
                  h(
                    Link,
                    {
                      key: l.label,
                      target: '_blank',
                      rel: 'noopener',
                      underline: 'hover',
                      href: l.url,
                      sx: { mb: '4px' },
                    },
                    l.label,
                  ),
                ),
                h(
                  Link,
                  {
                    key: 'rum_session',
                    target: '_blank',
                    rel: 'noopener',
                    underline: 'hover',
                    href: `https://app.datadoghq.com/rum/sessions?query=%40type%3Asession%20%40usr.email%3A${encodeURIComponent(
                      client_summary.email,
                    )}&cols=&from_ts=${new Date(
                      Date.now() - 86400000 * 30,
                    ).getTime()}&to_ts=${new Date().getTime()}&live=true`,
                    sx: { mb: '4px' },
                  },
                  'App session recordings',
                ),
              ],
            ),
          ]
        : [
            h(
              Box,
              {
                width: 180,
                height: 270,
                sx: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              },
              [h(CircularProgress)],
            ),
          ],
    ),
  ]);
}

export default ClientCard;
