import { useBaseData, BaseDataProvider } from '../../../common/base_data';
import { daysBetween } from '../../../common/util';
import {
  ClientDrillDown,
  format,
  PageDataGrid,
  QuickFilterSpec,
  DataGridEntryLink,
  PercentColorCell,
  SellingHistoryPanel,
} from '../../../common/components';
import { Stack, Box, Link } from '@mui/material';
import * as _ from 'ramda';
import { GridColDef } from '@mui/x-data-grid-pro';
import h from '../../../lib/react-hyperscript';
import { LeagueId, AmpPricingHistory } from '../../../common/types';
import InventoryPricingAdjustment from '../../../common/components/InventoryPricingAdjustment';
import * as api from '../../../common/api';
import {
  Sparkline,
  Sparkbar,
  PackageDrillDown,
  InventoryPricingHistoryPanel,
  FetchingPopover,
} from '../../../common/components';

////////////////////////////////////////////////////////////////////////////////
// ENVIRONMENT VARIABLES

const COMPS_PROXIMITY_WINDOW = parseFloat(
  process.env.REACT_APP_COMPS_PROXIMITY_WINDOW ?? '0.4',
);
const COMPS_SPARKBARS_MIN_OPACITY = parseFloat(
  process.env.REACT_APP_COMPS_SPARKBARS_MIN_OPACITY ?? '1',
);

////////////////////////////////////////////////////////////////////////////////

type LocalComp = {
  lpn: number;
  rpf: number;
};

const rpfBarOpacity = (rpf: number, inv_rpf: number) => {
  const max_p = COMPS_PROXIMITY_WINDOW / 2;
  const min_o = COMPS_SPARKBARS_MIN_OPACITY;
  const r = 1 - Math.abs(rpf / inv_rpf - 1);
  return min_o + ((1 - min_o) * (r - 1 + max_p)) / max_p;
};

const rpfSparkbarData = (
  inv_rpf: number,
  inv_price: number,
  local_copms: LocalComp[],
) => {
  const min = inv_price * 0.5;
  const max = inv_price * 1.5;
  const points = _.map(
    (lc) => ({ value: lc.lpn, opacity: rpfBarOpacity(lc.rpf, inv_rpf) }),
    local_copms,
  );
  const origin = inv_price;
  return { min, max, origin, points };
};

const geoSparkbarData = (inv_price: number, local_copms: LocalComp[]) => {
  const min = inv_price * 0.5;
  const max = inv_price * 1.5;
  const points = _.map((lc) => ({ value: lc.lpn, opacity: 1 }), local_copms);
  const origin = inv_price;
  return { min, max, origin, points };
};

type PriceEvent = {
  lp: number;
  snapped: string;
};

type RawPeriod = {
  start: string;
  end: string | null;
};

type Period = {
  start: Date;
  end: Date | null;
};

const lineOpacity = (listed_periods: Period[], snapped: Date) => {
  return listed_periods.some(
    ({ start, end }) => snapped >= start && (!end || snapped <= end),
  )
    ? 1
    : 0.3;
};

const sparklineData = (row: {
  updated: string;
  list_price: string;
  listed: string;
  delisted: string;
  price_history: PriceEvent[];
  listed_periods: RawPeriod[];
}) => {
  const prices = row.price_history;
  // map strings to Dates
  const periods = _.map(
    (p) => ({
      start: new Date(p.start),
      end: p.end ? new Date(p.end) : null,
    }),
    row.listed_periods,
  );
  const points = _.map(
    (p) => ({
      value: p.lp,
      opacity: lineOpacity(periods, new Date(p.snapped)),
    }),
    prices,
  );
  // add the current price to the back if more recent
  if (row.updated > prices[prices.length - 1].snapped) {
    points.push({
      value: parseFloat(row.list_price),
      opacity: lineOpacity(periods, new Date(row.updated)),
    });
  }
  const nums = _.map((p) => p.lp, prices);
  return {
    max: Math.max(...nums),
    min: Math.max(...nums) * 0.5,
    points,
  };
};

const column_specs: GridColDef[] = [
  {
    field: 'client_id',
    align: 'center',
    width: 80,
  },
  {
    field: 'client_name',
    headerName: 'name',
    flex: 1,
    minWidth: 150,
    maxWidth: 300,
    valueGetter: ({ row }) => row.client_name,
    renderCell: (item) => {
      return h(ClientDrillDown, {
        label: item.value,
        client_id: item.row.client_id,
      });
    },
  },

  {
    field: 'package_id',
    width: 80,
    renderCell: (params) =>
      DataGridEntryLink(
        { id: params.row.package_id },
        `/pricing/inventory?filter.01.package_id=equals%2C${params.row.package_id}&v=2`,
      ),
  },
  {
    field: 'package_short',
    minWidth: 200,
    valueGetter: ({ row }) =>
      `'${row.season - 2000} ${row.league} ${row.home_team_abbrev} • ${
        row.section
      } • ${row.row} • ${row.seats[0] + '-' + row.seats[row.seats.length - 1]}`,
    renderCell: (params) =>
      h(
        FetchingPopover,
        {
          fetchFn: api.getSellingHistory,
          args: ['ticket-package', params.row.package_id, params.row.season],
          renderPopup: (data: any) =>
            h(SellingHistoryPanel, { data, level: 'ticket-package' }),
        },
        [
          h(Stack, { direction: 'row', alignItems: 'center', height: '100%' }, [
            PackageDrillDown({
              label: params.value,
              package_id: params.row.package_id,
            }),
          ]),
        ],
      ),
  },
  { field: 'season', width: 75 },
  { field: 'league', width: 75 },
  {
    field: 'is_playoff',
    width: 75,
    type: 'boolean',
  },
  {
    field: 'team',
    width: 80,
    renderCell: (params) =>
      DataGridEntryLink(
        { id: params.row.team },
        `/pricing/inventory?filter.01.team=equals%2C${params.row.team}&filter.02.season=equals%2C${params.row.season}&filterOp=and&v=2`,
      ),
  },
  { field: 'section', width: 75 },
  { field: 'row', width: 50 },
  {
    field: 'seats',
    valueGetter: ({ row }) =>
      row.seats[0] + '-' + row.seats[row.seats.length - 1],
    width: 80,
  },

  {
    field: 'id',
    width: 120,
    renderCell: (params) =>
      DataGridEntryLink(
        { id: params.row.id },
        `/pricing/inventory/${params.id}`,
      ),
  },
  {
    field: 'tm_event_id',
    width: 100,
  },
  {
    field: 'skybox_event_id',
    width: 100,
  },
  {
    field: 'event_date_local',
    headerName: 'event_date',
    width: 160,
    valueFormatter: format.datetime,
  },
  {
    field: 'event_dow',
    headerName: 'dow',
    valueGetter: ({ row }) => row.event_date_local,
    valueFormatter: format.dayOfWeek,
    sortComparator: (a, b) => {
      return new Date(a).getDay() - new Date(b).getDay();
    },
    width: 60,
  },
  { field: 'event_name', minWidth: 260 },
  { field: 'opponent', width: 100 },
  { field: 'marketplace' },

  {
    field: 'list_price',
    headerName: '$list',
    align: 'right',
    width: 90,
    type: 'number',
    renderCell: ({ row, value }) =>
      h(
        FetchingPopover,
        {
          fetchFn: api.getInventoryPricingHistory,
          args: [row.id],
          renderPopup: (result: any) =>
            h(InventoryPricingHistoryPanel, { data: result }),
        },
        [
          h(
            Stack,
            {
              height: '100%',
              width: '100%',
              justifyContent: 'right',
              alignItems: 'center',
              direction: 'row',
            },
            [
              h(
                Box,
                {
                  sx: {
                    backgroundColor: row.amp ? '#ffffda' : 'inherit',
                    fontWeight: row.amp ? 'bold' : 'normal',
                    textAlign: 'right',
                    padding: '2px 4px',
                    width: 'fit-content',
                    borderRadius: '3px',
                  },
                },
                format.dollars({ value }),
              ),
            ],
          ),
        ],
      ),
  },
  {
    field: 'price_history',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }) => {
      if (row.price_history?.length) {
        const sparklineEl = h(Sparkline, {
          data: sparklineData(row),
          points_shown: 30,
          show_end_dot: !!row.sold,
        });

        return row.amp
          ? h(
              FetchingPopover,
              {
                fetchFn: api.getInventoryPricingHistory,
                args: [row.id],
                renderPopup: (result: any) =>
                  h(InventoryPricingHistoryPanel, { data: result }),
              },
              [sparklineEl],
            )
          : sparklineEl;
      }
      return null;
    },
  },

  {
    field: 'model_diff',
    headerName: 'modelΔ',
    align: 'right',
    valueFormatter: format.percent,
    type: 'number',
    width: 60,
    renderCell: PercentColorCell,
  },

  {
    field: 'rpf_comps_diff',
    headerName: 'rpf_compsΔ',
    align: 'right',
    valueFormatter: format.percent,
    valueGetter: ({ row }) => {
      const bottoms: number[] = (row.local_comps?.rpf.slice(0, 3) || []).map(
        (r: { lpn: number }) => r.lpn,
      );
      const list_price = row.list_price;
      if (bottoms.length && list_price) {
        const lpn_avg = bottoms.reduce((sum, a) => sum + a, 0) / bottoms.length;
        return list_price / lpn_avg - 1;
      }
    },
    type: 'number',
    width: 70,
    renderCell: PercentColorCell,
  },

  {
    field: 'rpf_comps',
    align: 'right',
    width: 100,
    renderCell: ({ row }) =>
      row.list_price && row.local_comps
        ? h(
            Box,
            {
              width: '100%',
              height: '100%',
              style: { cursor: 'pointer' },
            },
            [
              h(Sparkbar, {
                points_shown: 10,
                data: rpfSparkbarData(
                  Number(row.rpf),
                  row.list_price,
                  row.local_comps.rpf,
                ),
              }),
            ],
          )
        : null,
  },

  {
    field: 'geo_comps_diff',
    headerName: 'geo_compsΔ',
    align: 'right',
    valueFormatter: format.percent,
    valueGetter: ({ row }) => {
      const bottoms: number[] = (row.local_comps?.geo.slice(0, 3) || []).map(
        (r: { lpn: number }) => r.lpn,
      );
      const list_price = row.list_price;
      if (bottoms.length && list_price) {
        const lpn_avg = bottoms.reduce((sum, a) => sum + a, 0) / bottoms.length;
        return list_price / lpn_avg - 1;
      }
    },
    type: 'number',
    width: 70,
    renderCell: PercentColorCell,
  },

  {
    field: 'geo_comps',
    align: 'right',
    width: 100,
    renderCell: ({ row }) =>
      row.list_price && row.local_comps
        ? h(
            Box,
            {
              width: '100%',
              height: '100%',
              style: { cursor: 'pointer' },
            },
            [
              h(Sparkbar, {
                points_shown: 10,
                data: geoSparkbarData(row.list_price, row.local_comps.geo),
              }),
            ],
          )
        : null,
  },

  {
    field: 'target_price',
    headerName: '$target',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
    width: 80,
  },
  {
    field: 'target',
    headerName: 'target',
    align: 'right',
    valueFormatter: format.percent,
    type: 'number',
    width: 60,
    renderCell: PercentColorCell,
  },
  {
    field: 'list_change',
    headerName: 'list±',
    align: 'right',
    valueFormatter: format.percent,
    type: 'number',
    width: 60,
  },
  {
    field: 'sale_score',
    align: 'right',
    valueFormatter: format.percent,
    type: 'number',
    width: 100,
  },
  {
    field: 'erp',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
    width: 80,
  },
  {
    field: 'rpf',
    align: 'right',
    valueFormatter: format.percent,
    type: 'number',
    width: 70,
  },
  {
    field: 'erp_offset',
    headerName: 'erp_off',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
    width: 80,
  },
  {
    field: 'mp_offset',
    headerName: 'mp_off',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
    width: 80,
  },
  { field: 'status', align: 'right', width: 80 },
  {
    field: 'sold',
    width: 160,
    type: 'dateTime',
    valueGetter: ({ row }) => (row.sold ? new Date(row.sold) : null),
    valueFormatter: format.datetime,
  },
  {
    field: 'days_to',
    align: 'right',
    type: 'number',
    width: 80,
  },
  {
    field: 'days_listed',
    align: 'right',
    type: 'number',
    width: 100,
  },

  {
    field: 'days_queued',
    valueGetter: ({ row }) => {
      return (row.status_num === 1 && row.is_broadcasted) ||
        row.status_num === 2
        ? row.broadcasted
          ? daysBetween(new Date(row.listed), new Date(row.broadcasted))
          : null
        : row.status_num === 1
        ? daysBetween(new Date(row.listed), new Date())
        : null;
    },
    renderCell: ({ row, value }) =>
      h(
        Stack,
        {
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          sx: {
            backgroundColor:
              row.status_num === 1 && !row.is_broadcasted
                ? '#ffeaea'
                : 'inherit',
            fontWeight:
              row.status_num === 1 && !row.is_broadcasted ? 'bold' : 'normal',
            textAlign: 'right',
            paddingRight: '4px',
          },
        },
        _.isNil(value) ? null : value.toFixed(2),
      ),
    align: 'right',
    type: 'number',
    width: 100,
  },

  {
    field: 'wait',
    align: 'right',
    valueFormatter: format.percent,
    type: 'number',
    width: 60,
  },
  {
    field: 'amp',
    width: 75,
    type: 'boolean',
  },
];

const column_groups = [
  {
    groupId: 'Client',
    children: [{ field: 'client_id' }, { field: 'client_name' }],
  },

  {
    groupId: 'Package',
    children: [
      { field: 'package_id' },
      { field: 'package_short' },
      { field: 'is_shown' },
      { field: 'season' },
      { field: 'is_playoff' },
      { field: 'league' },
      { field: 'team' },
      { field: 'section' },
      { field: 'row' },
      { field: 'seats' },
    ],
  },

  {
    groupId: 'Inventory',
    children: [
      { field: 'id' },
      { field: 'tm_event_id' },
      { field: 'skybox_event_id' },
      { field: 'event_date_local' },
      { field: 'event_name' },
      { field: 'opponent' },
      { field: 'marketplace' },
      { field: 'event_dow' },
    ],
  },

  {
    groupId: 'Pricing',
    children: [
      { field: 'list_price' },
      { field: 'model_diff' },
      { field: 'rpf_comps_diff' },
      { field: 'rpf_comps' },
      { field: 'geo_comps_diff' },
      { field: 'geo_comps' },
      { field: 'price_history' },
      { field: 'target_price' },
      { field: 'target' },
      { field: 'list_change' },
      { field: 'sale_score' },
      { field: 'erp' },
      { field: 'rpf' },
      { field: 'erp_offset' },
      { field: 'mp_offset' },
    ],
  },

  {
    groupId: 'Other',
    children: [
      { field: 'amp' },
      { field: 'status' },
      { field: 'sold' },
      { field: 'days_listed' },
      { field: 'days_queued' },
      { field: 'days_to' },
      { field: 'wait' },
    ],
  },
];

const buildFilterSpecs = (baseData: BaseDataProvider) => {
  const filter_specs: QuickFilterSpec<
    'unlisted' | 'listed' | 'sold' | LeagueId | number
  >[] = [
    {
      id: 'season',
      label: 'Season',
      optionValues: _.map(
        (start_year) => ({
          value: start_year,
          label: start_year.toString(),
        }),
        baseData.getSeasonStartYears(),
      ),
    },
    {
      id: 'league',
      label: 'League',
      optionValues: _.map(
        (league_id) => ({ label: league_id, value: league_id }),
        baseData.getLeagueIds(),
      ),
    },
    { id: 'is_shown', label: 'Showing?', yesNoAny: true },
    {
      id: 'status',
      label: 'Status',
      optionValues: [
        { label: 'unlisted', value: 'unlisted' },
        { label: 'listed', value: 'listed' },
        { label: 'sold', value: 'sold' },
      ],
    },
    { id: 'amp', label: 'Amp?', yesNoAny: true },
  ];
  return filter_specs;
};

export default function InventoryPricingDataGrid({ rows }: { rows: any[] }) {
  const { baseData } = useBaseData();
  rows = baseData.hydrateTeams(baseData.hydrateClients(rows) as any[]);
  return PageDataGrid({
    id: 'ticket_packages_pricing',
    data_grid_id: 'id',
    rows: _.filter((r) => r.is_client || baseData.include_test_data, rows),
    column_specs,
    column_groups,
    filter_specs: buildFilterSpecs(baseData),
    onCellClick: ({ field, row }) => {
      if (field === 'rpf_comps') {
        alert(
          row.local_comps.rpf
            .map(
              (c: any) =>
                `$${c.lpn}   rpf: ${c.rpf}   ${c.section}.${c.row}.${
                  c.first_seat ?? ''
                }-${c.last_seat ?? ''}`,
            )
            .join('\n'),
        );
      }
      if (field === 'geo_comps') {
        alert(
          row.local_comps.geo
            .map(
              (c: any) =>
                `$${c.lpn}   rpf: ${c.rpf}   ${c.section}.${c.row}.${
                  c.first_seat ?? ''
                }-${c.last_seat ?? ''}`,
            )
            .join('\n'),
        );
      }
    },
  });
}
