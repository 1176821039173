import { clone } from 'ramda';

// UnsafeShareable
// A templated class that can store a variable and will send updates to
// any listeners. Anyone with a reference to it can also update the value.

export class UnsafeSharedValue<T> {
  private value: T;
  private listeners: Set<(value: T) => void> = new Set();

  constructor(initialValue: T) {
    this.value = initialValue;
  }

  get() {
    return this.value;
  }

  set(value: T) {
    this.value = value;
    this.listeners.forEach((listener) => listener(value));
  }

  subscribe(listener: (value: T) => void) {
    this.listeners.add(listener);
    return () => this.listeners.delete(listener);
  }
}

export type SharedValue<t> = {
  get: () => t;
  subscribe: (listener: (value: t) => void) => () => void;
};
