import * as _ from 'remeda';

// D3 has it's own data structures and helper functions for projecting
// a data set into a stacked repreresentation. I found them to be
// difficult to understand and difficult to type, so I wrote my own
// data stricture and helper.
//
// This function takes a flat data set and returns the stacked representation.
//
// Similar to the D3 representation, 2 element arrays are used to represnt the
// start and end point of each segment in a stack.
//

type FlatData = Record<string, unknown>[];

type StackedSeries<I> = {
  // name of the series
  name: string;

  // "index" is the position of the stack: (typically thex-axis value),
  // and "span" is the start and end of the stack segment) (typically the y-axis values)
  values: { index: I; span: [number, number] }[];

  // the maximum hieght of the stack (or minimum in case of negative values)
  limit: number;
};

const stackedSeries =
  <I>(
    // the property that identifies the series
    series_prop: string,
    // the property that identifies the placement of the stack (usually the x-axis value)
    index_prop: string,
    // the property that identifies the height of the stack segment (usually the y-axis value)
    val_prop: string,
    // The names of the series we want to include, specified in the order
    // they should be rendered, from bottom to top
    series_names: string[],
  ) =>
  (data: FlatData): StackedSeries<I>[] => {
    data = _.sortBy(data, (d) => d[index_prop] as any);
    const grouped = _.groupBy(data, (d) => d[index_prop] as PropertyKey);
    const series = Object.fromEntries(
      series_names.map((key) => [
        key,
        [] as { index: I; span: [number, number] }[],
      ]),
    );

    let limit = 0;

    for (const [, group] of Object.entries(grouped)) {
      const series_vals: Record<string, number> = {};
      for (const g of group) {
        series_vals[g[series_prop] as string] = g[val_prop] as number;
      }
      let start = 0;
      for (const key of series_names) {
        const end = start + (series_vals[key] ?? 0);
        series[key].push({
          index: group[0][index_prop] as I,
          span: [start, end],
        });
        start = end;
        if (Math.abs(end) > Math.abs(limit)) {
          limit = end;
        }
      }
    }
    return _.entries(series).map(([name, values]) => ({
      name,
      values,
      limit,
    }));
  };

export { stackedSeries, type StackedSeries };
