import * as d3 from 'd3';
import {
  AmpPricingHistory,
  AmpPriceUpdate,
  BasicCalibration,
  Period,
} from '../../types';
import * as _ from 'ramda';
import * as amp from '../../amp';
import { UnsafeSharedValue } from '../../util/shared_value';
import { addScrubber } from '.';

type GElement = d3.Selection<SVGGElement, undefined, null, undefined>;

const xScale = (
  data: AmpPricingHistory,
  y_axis_width: number,
  width: number,
) => {
  // Add X axis --> it is a date format
  const time_domain = d3.extent(data.price_updates, (d) => d.created) as [
    Date,
    Date,
  ];
  return d3.scaleTime().domain(time_domain).range([y_axis_width, width]);
};

const yScale = (
  data: AmpPricingHistory,
  x_axis_height: number,
  height: number,
) => {
  const max = d3.max(data.price_updates, (d) =>
    Math.max(d.price, d.effective_model_price),
  ) as number;
  const min = d3.min(data.price_updates, (d) =>
    Math.min(d.price, d.effective_model_price),
  ) as number;

  const top = max * 1.2;
  const bottom = Math.min(max * 0.3, min);

  return d3
    .scaleLinear()
    .domain([bottom, top])
    .range([height - x_axis_height, 0]);
};

const xAxis =
  (width: number) => (g: GElement, xN: d3.ScaleTime<number, number>) =>
    g.call(
      d3
        .axisBottom(xN)
        .ticks(width / 80)
        .tickSizeOuter(0),
    );

const priceHistorySvg =
  ({ width, height }: { width: number; height: number }) =>
  (data: AmpPricingHistory, selectedDate: UnsafeSharedValue<Date>) => {
    const x_axis_height = 20;
    const y_axis_width = 40;

    const svg = d3.create('svg');

    svg.attr('id', 'testtest').attr('width', width).attr('height', height);

    const x = xScale(data, y_axis_width, width);
    const y = yScale(data, x_axis_height, height);

    // Add x axis
    const x_axis = xAxis(width);
    const g_x_axis = svg
      .append('g')
      .attr('transform', 'translate(0,' + (height - x_axis_height) + ')')
      .call(x_axis, x);

    // Add Y axis
    svg
      .append('g')
      .attr('transform', 'translate(' + y_axis_width + ',0)')
      .call(d3.axisLeft(y).tickFormat(d3.format('$.0f')));

    const line = (series: 'price' | 'effective_model_price', xN: typeof x) =>
      d3
        .line<AmpPriceUpdate>()
        .x((d) => {
          return xN(d.created);
        })
        .y((d) => {
          return y(d[series]);
        });

    const clip_id = 'clip-' + data.price_updates[0].inventory_id;

    // Define the clipping area
    svg
      .append('defs')
      .append('svg:clipPath')
      .attr('id', clip_id)
      .append('rect')
      .attr('x', y_axis_width)
      .attr('y', 0)
      .attr('width', width - y_axis_width)
      .attr('height', height - x_axis_height);

    const split_by_periods = (
      b_periods: AmpPricingHistory['inventory']['broadcasted_periods'],
      price_updates: AmpPriceUpdate[],
    ) => {
      let start: Date = price_updates[0].created;
      const paths: (Period & {
        is_broadcasted: boolean;
        price_updates: AmpPriceUpdate[];
      })[] = [];
      for (const p of b_periods) {
        paths.push({
          start,
          end: p.start,
          is_broadcasted: false,
          price_updates: [],
        });
        paths.push({
          start: p.start,
          end: p.end,
          is_broadcasted: true,
          price_updates: [],
        });
        if (p.end) start = p.end;
      }

      if (paths.length === 0) {
        paths.push({
          start,
          end: null,
          is_broadcasted: false,
          price_updates: [],
        });
      }

      for (const pu of price_updates) {
        const path = paths.find(
          (p) => pu.created >= p.start && (!p.end || pu.created < p.end),
        );
        path?.price_updates.push(pu);
      }

      // Connect path segments
      for (let i = 0; i < paths.length - 1; i++) {
        if (paths[i + 1].price_updates[0]) {
          paths[i].price_updates.push(paths[i + 1].price_updates[0]);
        }
      }

      return paths;
    };

    const paths = split_by_periods(
      data.inventory.broadcasted_periods,
      data.price_updates,
    );

    // Add the graph features with clipping area
    const price_paths = paths.map((p) =>
      svg
        .append('g')
        .append('path')
        .datum(p.price_updates)
        .attr('clip-path', `url(#${clip_id})`)
        .attr('fill', 'none')
        .attr('stroke', amp.list_price_style.color)
        .attr('stroke-width', 2)
        .attr(
          'stroke-opacity',
          p.is_broadcasted
            ? amp.list_price_style.opacity
            : amp.unbroadcasted_style.opacity,
        )
        .attr('d', line('price', x)),
    );

    const model_path = svg
      .append('path')
      .datum(data.price_updates)
      .attr('clip-path', `url(#${clip_id})`)
      .attr('fill', 'none')
      .attr('stroke', amp.model_price_style.color)
      .attr('stroke-width', 6)
      .attr('stroke-opacity', amp.model_price_style.opacity)
      .attr('d', line('effective_model_price', x));

    const getClosestPriceUpdate = (date: Date) => {
      return data.price_updates.reduce(
        (prev: AmpPriceUpdate, curr: AmpPriceUpdate) =>
          Math.abs(curr.created.getTime() - date.getTime()) <
          Math.abs(prev.created.getTime() - date.getTime())
            ? curr
            : prev,
      );
    };

    addScrubber(
      svg,
      (x) => getClosestPriceUpdate(xN.invert(x)).created,
      selectedDate,
      {
        vertical_rule: {
          y1: 0,
          y2: height - x_axis_height,
        },
        region: {
          x: y_axis_width,
          y: 0,
          width: width - y_axis_width,
          height: height - x_axis_height,
        },
        invert: (date) => ({ x: xN(date) }),
      },
    );

    let xN = x;

    const handleZoom = (e: any) => {
      xN = e.transform.rescaleX(x); //.range([y_axis_width + 100, width]);
      price_paths.forEach((p) => {
        p.attr('d', line('price', xN));
      });

      model_path.attr('d', line('effective_model_price', xN));
      g_x_axis.call(x_axis, xN);
    };

    const zoom = d3
      .zoom<SVGSVGElement, undefined>()
      .on('zoom', handleZoom)
      .extent([
        [y_axis_width, 0],
        [width, height],
      ])
      .translateExtent([
        [y_axis_width, -Infinity],
        [width, Infinity],
      ]);

    svg.call(zoom).on('wheel.zoom', null);

    return svg.node() as SVGSVGElement;
  };

export { priceHistorySvg };
