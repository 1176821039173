import * as _ from 'remeda';
import { scaleOrdinal, schemePaired } from 'd3';
import {
  Adjustment,
  Calibration,
  ProCalibration,
  BasicCalibration,
} from './types';

// Define color scale

export const basic_package_calibration_classes = [
  'basic-global',
  'basic-league',
  'basic-package',
  'basic-team',
];

export const calibration_classes = [
  'broadcasted_linear_plot',
  'linear_plot',
  'seats_bonus',
  'proximity_linear',
  'basic-aisle_seats',
  'basic-event',
  ...basic_package_calibration_classes,
];

export const calibrationColor = scaleOrdinal([...schemePaired]).domain(
  calibration_classes,
);

export const formatCalibrationClass = (type: string, scope: string[]) =>
  type !== 'basic' ? type : 'basic-' + scope[0].split(':')[0];

export const calibrationClass = (cal: Calibration) =>
  formatCalibrationClass(
    Object.hasOwn(cal, 'amount') ? 'basic' : (cal as ProCalibration).type,
    cal.scope,
  );

export const adjustmentCalibrationClass = (adj: Adjustment) =>
  formatCalibrationClass(adj.type, adj.scope);

const pricing_color = 'DarkSlateGray';

export const list_price_style = {
  color: pricing_color,
  opacity: 1,
  stroke_width: 2,
};

export const unbroadcasted_style = {
  color: pricing_color,
  opacity: 0.2,
  stroke_width: 2,
};

export const model_price_style = {
  color: pricing_color,
  opacity: 0.5,
  stroke_width: 5,
};

export const amp_adjustment_style = {
  color: 'MediumVioletRed',
  opacity: 0.8,
  stroke_width: 2,
};

export const filterBasicPackageCalibrations = (
  calibrations: Calibration[],
): BasicCalibration[] => {
  const cal_classes = basic_package_calibration_classes;
  const latest_calibration: Record<string, number> = Object.fromEntries(
    _.zip(cal_classes, Array(cal_classes.length).fill(0)),
  );
  return calibrations.filter((c) =>
    cal_classes.includes(calibrationClass(c)),
  ) as BasicCalibration[];
};

// assumes calibrations are sorted by created
export const activeCalibrations = <T extends Calibration>(
  date: Date,
  calibrations: T[],
): T[] => {
  const latest_calibration: Record<string, T> = {};

  // find latest calibration of each kind that occurred before date
  for (const cal of calibrations) {
    const cls = calibrationClass(cal);
    if (cal.created <= date) {
      latest_calibration[cls] = cal;
    }
  }

  return Object.values(latest_calibration);
};

export const total_basic_amount = (cals: BasicCalibration[]) =>
  cals.reduce((acc, cal) => {
    return acc * (1 + cal.amount);
  }, 1) - 1;
