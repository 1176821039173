import h from '../../lib/react-hyperscript';
import { ReactElement, useState, FunctionComponentElement } from 'react';
import { useAsyncFn } from 'react-use';
import {
  Popover,
  Box,
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material';

const FetchingPopover = <Args extends any[], Result>({
  fetchFn,
  args,
  renderPopup,
  children,
}: {
  fetchFn: (...args: Args) => Promise<Result>;
  args: Args;
  renderPopup: (result: Result) => ReactElement;
  children?: any;
}) => {
  const [state, doFetch] = useAsyncFn(() => fetchFn(...args));
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!state.loading && !state.value)
      doFetch().catch(() => {
        console.error(state.error);
      });

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return h(Box, { height: '100%', width: '100%' }, [
    h(
      Box,
      {
        sx: { cursor: 'pointer', width: '100%', height: '100%' },
        onClick: handleClick,
      },
      children,
    ),
    h(
      Popover,
      {
        sx: { zIndex: 99999 },
        PaperProps: {
          sx: {
            overflow: 'visible', // Override the default overflow
          },
        },
        open: Boolean(anchorEl),
        anchorEl,
        onClose: handleClose,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      },
      [
        state.loading
          ? h(Card, [h(CardContent, [h(CircularProgress)])])
          : state.error
          ? h(Card, [h(CardContent, state.error.stack)])
          : state.value
          ? h(Card, [h(CardContent, [renderPopup(state.value)])])
          : null,
      ],
    ),
  ]);
};

export default FetchingPopover;
